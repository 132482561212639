import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Button, { LinkBtn } from '@dqp/common/components/Button/Button'
import Select from '@dqp/common/components/Select/Select'
import VisibleLargeScreens from '@dqp/common/components/VisibleLargeScreens/VisibleLargeScreens'
import VisibleMediumScreens from '@dqp/common/components/VisibleMediumScreens/VisibleMediumScreens'
import Tab from '#components/Tab/Tab'
import ResetSubscriptionTooltipItem from '#containers/ManageSubject/ManageSubjectTable/TooltipItems/ResetSubscriptionTooltipItem'
import routes from '#globals/routes'

const styles = {
  name: css`
    font-size: 28px;
    ${spacer.mrB20};
  `,
  select: css`
    flex-grow: 1;
    ${spacer.mrB20};
  `,
  spaceCreator: css`
    height: 50px;
  `,
  button: css`
    ${breakpoints({
      marginBottom: [20, 20, 0],
    })}
  `,
}

function Header({
  onTabClick,
  tabs,
  initialSelectedTab,
  subscription,
}) {
  const selectedTab = initialSelectedTab || tabs[0]
  const nameMarkup = (
    <Text
      size='xLarge'
      color={colors.white}
      weight='bold'
      css={styles.name}
    >
      {selectedTab.label} Questions
    </Text>
  )

  const buttonMarkup = (
    <>
      <LinkBtn
        to={routes.subjectDashboard.getPathFromParams({
          profileId: subscription.profile.id,
          subscriptionId: subscription.id,
        })}
        variant='black'
        size='small'
        className='ml-auto'
        css={[styles.button]}
      >
        VIEW PROFILE
      </LinkBtn>
      <ResetSubscriptionTooltipItem
        subscription={subscription}
        css={[styles.button, spacer.mrL20]}
        label={
          <Button variant='black' size='small'>
            RESET SUBJECT
          </Button>
        }
      />
    </>
  )

  const tabsMarkup = tabs.map(tab => (
    <Tab
      key={tab.value}
      isActive={selectedTab.value === tab.value}
      onClick={() => onTabClick(tab)}
      css={[spacer.mrR20, spacer.mrB20]}
    >
      {tab.label}
    </Tab>
  ))

  const spaceCreatorMarkup = <div css={styles.spaceCreator} />
  return (
    <>
      {/* Desktop view */}
      <VisibleLargeScreens>
        <div className='container-md' css={styles.container}>
          {spaceCreatorMarkup}
          {nameMarkup}
          <div className='d-flex align-items-start'>
            {tabsMarkup}
            {buttonMarkup}
          </div>
          {spaceCreatorMarkup}
        </div>
      </VisibleLargeScreens>

      {/* Mobile and table view */}
      <VisibleMediumScreens>
        <div className='container-md' css={styles.container}>
          {spaceCreatorMarkup}
          <div className='d-flex align-items-start ml-auto'>
            {buttonMarkup}
          </div>
          {nameMarkup}
          <Select
            css={styles.select}
            options={tabs}
            color={colors.black}
            onChange={data => onTabClick(data)}
            value={selectedTab}
            name='tab'
          />
        </div>
      </VisibleMediumScreens>
    </>
  )
}

Header.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  initialSelectedTab: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
}

export default Header
