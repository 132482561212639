import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

export const SUBMIT_QUESTION_MUTATION = gql`
  mutation submitQuestion(
    $subscriptionId: ID!
    $questionId: ID!
    $optionsIds: [ID]!
  ) {
    submitQuestion(
      input: {
        subscription_id: $subscriptionId
        question_id: $questionId
        option_ids: $optionsIds
      }
    ) {
      question {
        id
        question
        note
        type
        category {
          id
          name
        }
        options {
          id
          option
        }
      }
      attempt {
        was_correct
        started_at
        answered_options {
          id
          option
          is_correct
        }
        time_elapsed
        finished_at
      }
    }
  }
`
export const useSubmitQuestion = options => {
  const [submitQuestion, { data, loading, error }] = useMutation(
    SUBMIT_QUESTION_MUTATION,
    options,
  )

  return { submitQuestion, data, loading, error }
}
