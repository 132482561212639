import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { spacer } from '@dqp/common/styles/utilities'
import { get, pickBy, uniqBy, sortBy } from 'lodash-es'
import QuestionsTable from './QuestionsTable/QuestionsTable'
import Header from './Header'
import Filter from '#components/Filter/Filter'
import { useGetSubscriptionData } from '#api/getSubscriptionData'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import { QuestionProvider } from '#context/QuestionContext'

const tabs = [
  { value: `all`, label: `All` },
  { value: `answered`, label: `Answered` },
  { value: `unanswered`, label: `Unanswered` },
]

function Questions({ profileId, subscriptionId }) {
  const {
    subscriptionData,
    loading,
    error,
  } = useGetSubscriptionData({ subscriptionId })

  const [filterType, setFilterType] = useState(tabs[0].value)
  const [selectedCategories, setSelectedCategories] = useState({})

  useEffect(() => {
    setSelectedCategories({})
  }, [filterType])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Error error={JSON.stringify(error)} />
  }

  const {
    questionsWithNumberAttached: questions,
    subscription,
    next_question_release_time: nextQuestionReleaseTime,
  } = subscriptionData.getSubscriptionData

  const onTabClick = tab => {
    setFilterType(tab.value)
  }

  const questionFilteredByType = questions.filter(question => {
    const wasCorrect = get(question, 'attempt.was_correct', null)
    if (filterType === 'all') return true
    if (filterType === 'answered' && wasCorrect != null) return true
    if (filterType === 'unanswered' && wasCorrect == null) return true

    return false
  })

  const selectedCategoriesLength = Object.keys(
    pickBy(selectedCategories, Boolean),
  ).length

  const questionFilteredByCategory = questionFilteredByType.filter(
    question =>
      selectedCategoriesLength
        ? selectedCategories[question.question.category.id]
        : true,
  )

  const allCategories = sortBy(
    uniqBy(
      questionFilteredByType.map(({ question }) => ({
        value: question.category.id,
        label: question.category.name,
      })),
      'value',
    ),
    'label',
  )

  return (
    <>
      <Header
        tabs={tabs}
        onTabClick={onTabClick}
        initialSelectedTab={tabs.find(
          tab => tab.value === filterType,
        )}
        subscription={subscription}
      />
      <div className='container-md'>
        <Filter
          trigger='FILTERS'
          categories={allCategories}
          css={spacer.mrB20}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      </div>
      <QuestionProvider
        profileId={profileId}
        subscriptionId={subscriptionId}
      >
        <QuestionsTable
          questions={questionFilteredByCategory}
          subscription={subscription}
          nextQuestionReleaseTime={nextQuestionReleaseTime}
        />
      </QuestionProvider>
    </>
  )
}

Questions.propTypes = {
  subscriptionId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
}

export default Questions
