import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  colors,
  spacer,
  misc,
  mixins,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import { css } from '@emotion/core'
import { useInterval } from 'react-use'
import VisibleLargeScreens from '@dqp/common/components/VisibleLargeScreens/VisibleLargeScreens'
import VisibleMediumScreens from '@dqp/common/components/VisibleMediumScreens/VisibleMediumScreens'
import { styles } from './QuestionRow'
import { subscriptionTypes } from '#globals/constants'
import UpgradeSubscription from '#containers/ManageSubject/ManageSubjectTable/Buttons/UpgradeSubscription'
import lock from '#images/lock.svg'
import secondsToDHMS from '#utils/secondsToDHMS'

const cardStyles = {
  cardContainer: css`
    background-color: ${colors.yellow};
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
    overflow: hidden;
    ${mixins.transition()};
    ${spacer.mrB20};
  `,
  header: css`
    padding: 20px 15px;
    border-bottom: 1px solid ${colors.greyLight};
  `,
  stack: css`
    ${spacer.padLR20};
    ${spacer.padBT20};
  `,
}
const UpgradeSubscriptionRow = ({
  subscription,
  nextQuestionReleaseTime,
  questionNumber,
}) => {
  const [timer, setTimer] = useState(nextQuestionReleaseTime || 0)
  useInterval(
    () => setTimer(timer - 1),
    nextQuestionReleaseTime ? 1000 : null,
  )

  const isMonthlySubscription =
    subscription.type === subscriptionTypes.MONTHLY
  const isYearlySubscription =
    subscription.type === subscriptionTypes.YEARLY

  if (isYearlySubscription)
    // returning a non-null/non-self-closing/non-empty component is important otherwise react-virtual breaks
    // height:0 will also break it.
    return <div style={{ height: 1 }}>&nbsp;</div>

  const getUpgradeTextForDesktop = () => {
    if (isMonthlySubscription) {
      if (nextQuestionReleaseTime == null)
        return <strong>All Questions unlocked!</strong>

      return (
        <span>
          <strong>Next Question</strong> will be unlocked in{' '}
          {secondsToDHMS(timer)}
        </span>
      )
    }

    return <strong>Next Question locked</strong>
  }

  const getUpgradeTextForTabledAndMobile = () => {
    if (isMonthlySubscription) {
      if (nextQuestionReleaseTime == null)
        return <div css={spacer.mrB20}>All Questions unlocked!</div>

      return (
        <div css={spacer.mrB20}>
          will be unlocked in {secondsToDHMS(timer)}
        </div>
      )
    }
    return null
  }

  const upgradeButton = (
    <UpgradeSubscription
      subscription={subscription}
      buttonText='UPGRADE FOR MORE QUESTIONS'
      buttonVariant='black'
    />
  )

  return (
    <>
      <VisibleLargeScreens>
        <div
          className='d-flex justify-content-between'
          css={css`
            box-shadow: ${misc.boxShadow.default};
            border-radius: ${misc.borderRadius.md};
            background: ${colors.yellow};
            overflow: hidden;
            ${spacer.mrBT10};
          `}
        >
          <Text size='large' color={colors.black} css={styles.column}>
            <img src={lock} alt='lock-svg' css={styles.img} />
            {getUpgradeTextForDesktop()}
          </Text>

          <Text size='medium' color={colors.grey} css={styles.column}>
            {upgradeButton}
          </Text>
        </div>
      </VisibleLargeScreens>
      <VisibleMediumScreens>
        <div
          css={cardStyles.cardContainer}
          role='button'
          tabIndex='-1'
          onKeyDown={() => {}}
        >
          <header
            css={cardStyles.header}
            className='d-flex align-items-center'
          >
            <img src={lock} alt='lock-svg' css={styles.img} />
            <Text
              size='large'
              weight='bold'
              color={colors.black}
              css={cardStyles.td}
            >
              Question {questionNumber}
            </Text>
          </header>
          <div className='container-md'>
            <div className='row' css={spacer.padBT20}>
              <div className='col-12'>
                {getUpgradeTextForTabledAndMobile()}
              </div>
              <div className='col-12'>{upgradeButton}</div>
            </div>
          </div>
        </div>
      </VisibleMediumScreens>
    </>
  )
}

UpgradeSubscriptionRow.propTypes = {
  subscription: PropTypes.object.isRequired,
  questionNumber: PropTypes.number.isRequired,
  nextQuestionReleaseTime: PropTypes.any,
}

UpgradeSubscriptionRow.defaultProps = {
  nextQuestionReleaseTime: null,
}

export default UpgradeSubscriptionRow
