import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useVirtual } from 'react-virtual'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import { last, get, debounce } from 'lodash-es'
import { useEvent } from 'react-use'
import QuestionRow from './QuestionRow'
import UpgradeSubscriptionRow from './UpgradeSubscriptionRow'
import {
  useQuestionContext,
  questionActionTypes,
  questionModalTypes,
} from '#context/QuestionContext'

const MIN_HEIGHT = 500
const DesktopView = ({
  questions,
  subscription,
  nextQuestionReleaseTime,
}) => {
  const data = [...questions, { id: 'upgradeRow' }]
  const parentRef = useRef()
  const rowVirtualizer = useVirtual({
    size: data.length,
    parentRef,
  })
  const [height, setHeight] = useState(MIN_HEIGHT)
  const { dispatch } = useQuestionContext()
  const lastQuestion = last(questions) || {}
  const lastQuestionNumber = get(lastQuestion, 'question.number') + 1

  const calculateAndSetHeight = () => {
    if (!parentRef.current || !window) return
    const { innerHeight } = window
    const top = parentRef.current.offsetTop
    setHeight(Math.max(innerHeight - top, MIN_HEIGHT))
  }

  const onResize = useCallback(
    debounce(calculateAndSetHeight, 100),
    [],
  )

  useEvent('resize', onResize)
  useEffect(() => {
    // directly calling calculateAndSetHeight doesnt set height currectly, cuz browser hasnt painted everything yet and it gets set before layout is completely rendered.
    setTimeout(calculateAndSetHeight, 0)
  }, [onResize])
  return (
    <>
      <div
        className='d-flex'
        css={css`
          border-radius: 4px;
          overflow: hidden;
          padding: 5px 0;
          background-color: #f3f3f3;
        `}
      >
        <Text
          weight='bold'
          color={colors.black}
          size='xSmall'
          css={css`
            min-width: 33%;
            ${spacer.padLR20};
          `}
        >
          QUESTION
        </Text>
        <Text
          weight='bold'
          color={colors.black}
          size='xSmall'
          css={css`
            min-width: 20%;
            ${spacer.padLR20};
          `}
        >
          TIME TAKEN
        </Text>
        <Text
          weight='bold'
          color={colors.black}
          size='xSmall'
          css={spacer.padLR20}
        >
          CATEGORY
        </Text>
      </div>
      <div ref={parentRef} style={{ height, overflow: `auto` }}>
        <div
          style={{
            height: rowVirtualizer.totalSize,
            width: `100%`,
            position: `relative`,
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: data[virtualRow.index],
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {data[virtualRow.index].id === `upgradeRow` ? (
                <UpgradeSubscriptionRow
                  subscription={subscription}
                  nextQuestionReleaseTime={nextQuestionReleaseTime}
                  questionNumber={lastQuestionNumber + 1}
                />
              ) : (
                <QuestionRow
                  {...data[virtualRow.index]}
                  onClick={() => {
                    dispatch({
                      type: questionActionTypes.SET_CURRENT_QUESTION,
                      payload: data[virtualRow.index],
                    })
                    dispatch({
                      type: questionActionTypes.SET_MODAL,
                      payload: questionModalTypes.CONTAINER_MODAL,
                    })
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

DesktopView.propTypes = {
  questions: PropTypes.array.isRequired,
  subscription: PropTypes.object.isRequired,
  nextQuestionReleaseTime: PropTypes.any,
}
DesktopView.defaultProps = {
  nextQuestionReleaseTime: null,
}
export default React.memo(DesktopView)
