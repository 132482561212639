import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const UPDATE_QUESTION_TIMER_MUTATION = gql`
  mutation updateQuestionTimer(
    $subscriptionId: ID!
    $questionId: ID!
    $timeElapsed: Int!
  ) {
    updateQuestionTimer(
      input: {
        subscription_id: $subscriptionId
        question_id: $questionId
        time_elapsed: $timeElapsed
      }
    ) {
      attempt {
        was_correct
        started_at
        answered_options {
          id
          option
          is_correct
        }
        time_elapsed
        finished_at
      }
      question {
        id
        category {
          id
          name
        }
      }
    }
  }
`
export const useUpdateQuestionTimer = options => {
  const [updateQuestionTimer, { data, loading, error }] = useMutation(
    UPDATE_QUESTION_TIMER_MUTATION,
    options,
  )

  return { updateQuestionTimer, data, loading, error }
}
