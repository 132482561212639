import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { css } from '@emotion/core'
import QuestionModalContainer from './QuestionModalContainer'
import AnswerModalContainer from './AnswerModalContainer'
import {
  useQuestionContext,
  questionActionTypes,
} from '#context/QuestionContext'
import ModalContainer from '#components/ModalContainer/ModalContainer'

const styles = {
  modalContainer: css`
    padding: 0 !important;
  `,
  crossBtnStyles: css`
    position: fixed;
  `,
  modalOuterStyles: css`
    max-width: 860px;
  `,
}

const QuestionAndAnswerModalContainer = ({
  currentQuestion,
  isOpen,
  closeModal,
  questions,
}) => {
  const { dispatch } = useQuestionContext()
  const currentQuestionIndex = questions.findIndex(
    question => question.question.id === currentQuestion.question.id,
  )

  const previousQuestion =
    currentQuestionIndex - 1 >= 0
      ? questions[currentQuestionIndex - 1]
      : null

  const nextQuestion =
    currentQuestionIndex + 1 < questions.length
      ? questions[currentQuestionIndex + 1]
      : null

  const onNextButtonClick = () => {
    dispatch({
      type: questionActionTypes.SET_CURRENT_QUESTION,
      payload: nextQuestion,
    })
  }

  const onPreviousButtonClick = () => {
    dispatch({
      type: questionActionTypes.SET_CURRENT_QUESTION,
      payload: previousQuestion,
    })
  }

  const wasCorrect = get(currentQuestion, 'attempt.was_correct', null)

  const [scrollTop, setScrollTop] = React.useState(0)
  useEffect(() => {
    setScrollTop(0)
    // if question is changed, reset scrollTop, so that its doesnt scrollTop for other questions too
  }, [currentQuestionIndex])
  return (
    <ModalContainer
      isOpen={isOpen}
      closeModal={closeModal}
      containerStyles={styles.modalContainer}
      crossBtnStyles={styles.crossBtnStyles}
      shouldCloseOnOverlayClick={false}
      modalContainerStyles={styles.modalOuterStyles}
    >
      {wasCorrect == null ? (
        <QuestionModalContainer
          closeModal={closeModal}
          question={currentQuestion}
          nextQuestion={nextQuestion}
          previousQuestion={previousQuestion}
          onNextButtonClick={onNextButtonClick}
          onPreviousButtonClick={onPreviousButtonClick}
          setScrollTop={setScrollTop}
        />
      ) : (
        <AnswerModalContainer
          question={currentQuestion}
          onNextButtonClick={onNextButtonClick}
          nextQuestion={nextQuestion}
          closeModal={closeModal}
          scrollTop={scrollTop}
        />
      )}
    </ModalContainer>
  )
}

QuestionAndAnswerModalContainer.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
}

export default QuestionAndAnswerModalContainer
