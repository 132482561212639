import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import AnswerModal, { AnswerModalPropTypes } from './AnswerModal'
import { useQuestionContext } from '#context/QuestionContext'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import { GET_ANSWERED_QUESTION_QUERY } from '#api/getAnsweredQuestion'

const AnswerModalContainer = ({
  question,
  closeModal,
  onNextButtonClick,
  nextQuestion,
  scrollTop,
}) => {
  const { state } = useQuestionContext()
  const { data, loading, error } = useQuery(
    GET_ANSWERED_QUESTION_QUERY,
    {
      variables: {
        subscriptionId: state.subscriptionId,
        questionId: question.question.id,
      },
    },
  )

  if (loading) return <Loader isRelative height={350} />
  if (error) return <Error error={JSON.stringify(error)} />
  return (
    <AnswerModal
      question={{
        ...data.getAnsweredQuestion,
        question: {
          ...data.getAnsweredQuestion.question,
          number: question.question.number,
        },
      }}
      closeModal={closeModal}
      onNextButtonClick={onNextButtonClick}
      nextQuestion={nextQuestion}
      scrollTop={scrollTop}
    />
  )
}

AnswerModalContainer.propTypes = AnswerModalPropTypes
export default AnswerModalContainer
