import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import VisibleLargeScreens from '@dqp/common/components/VisibleLargeScreens/VisibleLargeScreens'
import VisibleMediumScreens from '@dqp/common/components/VisibleMediumScreens/VisibleMediumScreens'
import { useLocation } from '@reach/router'
import { get } from 'lodash-es'
import { navigate } from 'gatsby'
import QuestionAndAnswerModalContainer from '../QuestionModal/QuestionAndAnswerModalContainer'
import DesktopView from './DesktopView'
import TabletAndMobileTable from './TabletAndMobileView'
import {
  useQuestionContext,
  questionModalTypes,
  questionActionTypes,
} from '#context/QuestionContext'
import routes from '#globals/routes'

function QuestionsTable({
  questions,
  subscription,
  nextQuestionReleaseTime,
}) {
  const { state, dispatch } = useQuestionContext()
  const location = useLocation()
  const questionNumber = get(location, 'state.questionNumber')

  useEffect(() => {
    if (questionNumber) {
      dispatch({
        type: questionActionTypes.SET_CURRENT_QUESTION,
        payload: questions.find(
          question => question.question.number === questionNumber,
        ),
      })
      dispatch({
        type: questionActionTypes.SET_MODAL,
        payload: questionModalTypes.CONTAINER_MODAL,
      })
      // to prevent question opening automatically on refresh
      navigate(routes.questionDashboard.getPathFromParams(state), {
        replace: true,
      })
    }
  }, [questionNumber, state, dispatch, questions])

  useEffect(() => {
    const mainCtr = document.getElementById('content-js')

    mainCtr.style.paddingBottom = 0
    return () => {
      mainCtr.style.paddingBottom = `30px`
    }
  }, [])
  return (
    <>
      <div className='container-md'>
        <VisibleLargeScreens>
          <DesktopView
            questions={questions}
            subscription={subscription}
            nextQuestionReleaseTime={nextQuestionReleaseTime}
          />
        </VisibleLargeScreens>
        <VisibleMediumScreens>
          <TabletAndMobileTable
            questions={questions}
            subscription={subscription}
            nextQuestionReleaseTime={nextQuestionReleaseTime}
          />
        </VisibleMediumScreens>
      </div>

      {state.currentQuestion && (
        <QuestionAndAnswerModalContainer
          currentQuestion={state.currentQuestion}
          questions={questions}
          isOpen={
            state.modalType === questionModalTypes.CONTAINER_MODAL
          }
          closeModal={() =>
            dispatch({
              type: questionActionTypes.RESET,
            })
          }
        />
      )}
    </>
  )
}
QuestionsTable.propTypes = {
  questions: PropTypes.array.isRequired,
  subscription: PropTypes.object.isRequired,
  nextQuestionReleaseTime: PropTypes.any,
}
QuestionsTable.defaultProps = {
  nextQuestionReleaseTime: null,
}
export default React.memo(QuestionsTable)
