import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  misc,
  spacer,
  mixins,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import { last, get, debounce } from 'lodash-es'
import { useEvent } from 'react-use'
import { useVirtual } from 'react-virtual'
import { QuestionRowProps, getImg } from './QuestionRow'
import UpgradeSubscriptionRow from './UpgradeSubscriptionRow'
import {
  useQuestionContext,
  questionActionTypes,
  questionModalTypes,
} from '#context/QuestionContext'
import secondsToDHMS from '#utils/secondsToDHMS'

const styles = {
  cardContainer: css`
    background-color: ${colors.white};
    cursor: pointer;
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
    overflow: hidden;
    &:hover {
      background-color: ${colors.greyLightest};
      cursor: pointer;
    }
    ${spacer.mrB20};
    ${mixins.transition()};
  `,
  header: css`
    padding: 20px 15px;
    border-bottom: 1px solid ${colors.greyLight};
    background-color: #f3f3f3;
  `,
  stack: css`
    ${spacer.padLR20};
    ${spacer.padBT20};
  `,
}

const labelStyleProps = {
  size: `xSmall`,
  color: colors.grey,
  weight: `bold`,
}

const textStyleProps = {
  size: `medium`,
  color: colors.black,
  as: `span`,
  css: css`
    ${mixins.respondToMax(misc.viewport['b-vp'])} {
      font-size: 14px;
    }
  `,
}
const MIN_HEIGHT = 500
function TabletAndMobileView({
  questions,
  subscription,
  nextQuestionReleaseTime,
}) {
  const data = [
    ...questions,
    // this is used so we can render upgrade row within the block
    { id: 'upgradeRow' },
  ]
  const parentRef = useRef()
  const rowVirtualizer = useVirtual({
    size: data.length,
    parentRef,
  })
  const { dispatch } = useQuestionContext()
  const [height, setHeight] = useState(MIN_HEIGHT)
  const lastQuestion = last(questions) || {}
  const lastQuestionNumber = get(lastQuestion, 'question.number')

  const calculateAndSetHeight = () => {
    if (!parentRef.current || !window) return
    const { innerHeight } = window
    const top = parentRef.current.offsetTop
    setHeight(Math.max(innerHeight - top, MIN_HEIGHT))
  }

  const onResize = useCallback(
    debounce(calculateAndSetHeight, 100),
    [],
  )

  useEvent('resize', onResize)

  useEffect(() => {
    // directly calling calculateAndSetHeight doesnt set height currectly, cuz browser hasnt painted everything yet and it gets set before layout is completely rendered.
    setTimeout(calculateAndSetHeight, 0)
  }, [onResize])

  return (
    <div ref={parentRef} style={{ height, overflow: `auto` }}>
      <div
        style={{
          height: rowVirtualizer.totalSize,
          width: `100%`,
          position: `relative`,
        }}
      >
        {rowVirtualizer.virtualItems.map(virtualRow => (
          <div
            key={virtualRow.index}
            ref={virtualRow.measureRef}
            style={{
              position: `absolute`,
              top: 0,
              left: 0,
              width: `100%`,
              height: data[virtualRow.index],
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            {data[virtualRow.index].id === `upgradeRow` ? (
              <UpgradeSubscriptionRow
                subscription={subscription}
                nextQuestionReleaseTime={nextQuestionReleaseTime}
                questionNumber={lastQuestionNumber + 1}
              />
            ) : (
              <Card
                {...data[virtualRow.index]}
                onClick={() => {
                  dispatch({
                    type: questionActionTypes.SET_CURRENT_QUESTION,
                    payload: data[virtualRow.index],
                  })
                  dispatch({
                    type: questionActionTypes.SET_MODAL,
                    payload: questionModalTypes.CONTAINER_MODAL,
                  })
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const Card = ({ attempt, question, onClick }) => {
  return (
    <div
      css={styles.cardContainer}
      onClick={onClick}
      role='button'
      tabIndex='-1'
      onKeyDown={() => {}}
    >
      <header
        css={styles.header}
        className='d-flex align-items-center'
      >
        {getImg(attempt)}
        <Text size='large' color={colors.black} css={styles.td}>
          Question {question.number}
        </Text>
      </header>
      <div className='container-md'>
        <div className='row' css={spacer.padBT20}>
          <div className='col-6' css={spacer.mrB10}>
            <Text {...labelStyleProps}>Category</Text>
            <Text {...textStyleProps}>{question.category.name}</Text>
          </div>
          <div className='col-6'>
            <Text {...labelStyleProps}>Time taken</Text>
            <Text {...textStyleProps}>
              {secondsToDHMS((attempt && attempt.time_elapsed) || 0)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
Card.propTypes = QuestionRowProps
TabletAndMobileView.propTypes = {
  questions: PropTypes.array.isRequired,
  nextQuestionReleaseTime: PropTypes.any,
  subscription: PropTypes.object.isRequired,
}
TabletAndMobileView.defaultProps = {
  nextQuestionReleaseTime: null,
}
export default TabletAndMobileView
