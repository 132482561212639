import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Button from '@dqp/common/components/Button/Button'
import stylesForInnerHTML from '@dqp/common/styles/stylesForInnerHTML'
import styles from './styles'
import Pill from '#components/Pill/Pill'
import OptionBar from '#components/OptionBar/OptionBar'
import Stack from '#components/Stack/Stack'
import { defaultNoteField } from '#globals/constants'
import getVimeoId from '#utils/getVimeoId'

const getChecked = option => {
  if (option.was_chosen && option.is_correct) return true
  if (option.was_chosen && !option.is_correct) return true
  if (!option.was_chosen && option.is_correct) return true
  return false
}

const getStatus = option => {
  if (option.was_chosen && option.is_correct) return 'correct'
  if (option.was_chosen && !option.is_correct) return 'incorrect'
  if (!option.was_chosen && option.is_correct) return 'correct'
  return ''
}

const renderText = option => {
  if (!option.was_chosen) return <div css={spacer.mrB10} />
  if (option.is_correct)
    return (
      <Text
        color={colors.charcoal}
        css={[spacer.mrB10, spacer.mrT5]}
        size='small'
      >
        Correct! Watch the tutorial below. It contains an explanation
        as well as a new question for you to try.
      </Text>
    )
  return (
    <Text
      color={colors.charcoal}
      css={[spacer.mrB10, spacer.mrT5]}
      size='small'
    >
      You answered incorrectly. Watch the tutorial below. It contains
      an explanation as well as a new question for you to try.
    </Text>
  )
}

const AnswerModal = ({
  question,
  nextQuestion,
  closeModal,
  onNextButtonClick,
  scrollTop,
}) => {
  const note = question.question.note || defaultNoteField

  useEffect(() => {
    if (scrollTop) {
      document.querySelector(
        '.ReactModal__Overlay',
      ).scrollTop = scrollTop
    }
  }, [scrollTop])
  return (
    <div css={styles.container}>
      <header
        css={styles.header}
        className='d-flex justify-content-between align-items-center'
      >
        <Title color={colors.black} size='xxLarge'>
          Question {question.question.number}
        </Title>
        <Pill text={question.question.category.name} type='muted' />
      </header>
      <Stack css={styles.stack} spacing={30}>
        <Text css={styles.hint} color={colors.charcoal}>
          {note}
        </Text>
        <div
          css={stylesForInnerHTML}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: question.question.question,
          }}
        />

        <div>
          {question.optionsListWithChosenOptions.map(option => (
            <React.Fragment key={option.id}>
              <OptionBar
                {...option}
                option={option.option}
                multi={question.question.type === 'MULTI_SELECT'}
                name={question.question.id}
                value={option.id}
                onChange={() => {}}
                checked={getChecked(option)}
                status={getStatus(option)}
                pointerEvents='none'
              />
              {renderText(option)}
            </React.Fragment>
          ))}
        </div>
        {question.question.solution_video && (
          <div>
            <Text color={colors.black} weight='bold'>
              Tutorial
            </Text>
            <div css={styles.video}>
              <iframe
                title={`question ${question.question.number} solution video`}
                src={`https://player.vimeo.com/video/${getVimeoId(
                  question.question.solution_video,
                )}`}
                width='640'
                height='360'
                frameBorder='0'
                allow='autoplay; fullscreen'
                allowFullScreen
              />
            </div>
          </div>
        )}
        <div className='d-flex'>
          <Button
            variant='black'
            size='small'
            css={spacer.mrR20}
            onClick={closeModal}
          >
            CLOSE
          </Button>
          {nextQuestion && (
            <Button
              variant='blue'
              size='small'
              onClick={onNextButtonClick}
            >
              NEXT QUESTION
            </Button>
          )}
        </div>
      </Stack>
    </div>
  )
}
export const AnswerModalPropTypes = {
  question: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  nextQuestion: PropTypes.any,
  scrollTop: PropTypes.number,
}
AnswerModal.propTypes = AnswerModalPropTypes
AnswerModal.defaultProps = {
  nextQuestion: null,
  scrollTop: 0,
}
export default AnswerModal
