import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import stylesForInnerHTML from '@dqp/common/styles/stylesForInnerHTML'
import Text from '@dqp/common/components/Text/Text'
import Button from '@dqp/common/components/Button/Button'
import { LeftArrow, RightArrow } from '@dqp/common/components/icons'
import { pickBy } from 'lodash-es'
import styles from './styles'
import Pill from '#components/Pill/Pill'
import OptionBar from '#components/OptionBar/OptionBar'
import Stack from '#components/Stack/Stack'
import { defaultNoteField } from '#globals/constants'

function QuestionModal({
  question,
  onSubmit,
  isSubmitting,
  previousQuestion,
  nextQuestion,
  onNextButtonClick,
  onPreviousButtonClick,
}) {
  const [clickedOptions, setClickedOptions] = useState({})
  const handleChange = e => {
    const { value, checked } = e.target
    if (question.type === 'MULTI_SELECT') {
      setClickedOptions(s => ({ ...s, [value]: checked }))
    } else {
      setClickedOptions({ [value]: checked })
    }
  }
  const note = question.note || defaultNoteField
  const selectedOptionIds = Object.keys(
    pickBy(clickedOptions, Boolean),
  )
  const isAnyOptionSelected = selectedOptionIds.length > 0
  return (
    <div css={styles.container}>
      <header
        css={styles.header}
        className='d-flex justify-content-between align-items-center'
      >
        <Title color={colors.black} size='xxLarge'>
          Question {question.number}
        </Title>
        <Pill text={question.category.name} type='muted' />
      </header>
      <Stack css={styles.stack} spacing={30}>
        <Text css={styles.hint} color={colors.charcoal}>
          {note}
        </Text>
        <div
          css={stylesForInnerHTML}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: question.question }}
        />

        <div>
          {question.options.map(option => (
            <OptionBar
              {...option}
              option={option.option}
              multi={question.type === 'MULTI_SELECT'}
              name={question.id}
              css={spacer.mrB10}
              checked={clickedOptions[option.id]}
              value={option.id}
              onChange={handleChange}
              key={option.id}
            />
          ))}
        </div>
        <Button
          variant='black'
          size='medium'
          onClick={() => onSubmit(selectedOptionIds)}
          isLoading={isSubmitting}
          disabled={isSubmitting || !isAnyOptionSelected}
        >
          SUBMIT
        </Button>
      </Stack>

      {previousQuestion == null && nextQuestion == null ? null : (
        <footer className='d-flex' css={styles.footer}>
          {previousQuestion && (
            <Button onClick={onPreviousButtonClick}>
              <Text
                size='small'
                as='span'
                color={colors.charcoal}
                className='d-flex align-items-center'
              >
                <LeftArrow css={[styles.arrow, spacer.mrR10]} />
                Question {previousQuestion.question.number}
              </Text>
            </Button>
          )}
          {nextQuestion && (
            <Button onClick={onNextButtonClick} className='ml-auto'>
              <Text
                size='small'
                as='span'
                color={colors.charcoal}
                className='d-flex align-items-center'
              >
                Question {nextQuestion.question.number}
                <RightArrow css={[styles.arrow, spacer.mrL10]} />
              </Text>
            </Button>
          )}
        </footer>
      )}
    </div>
  )
}
export const QuestionModalPropTypes = {
  question: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  nextQuestion: PropTypes.any,
  previousQuestion: PropTypes.any,
  onNextButtonClick: PropTypes.func.isRequired,
  onPreviousButtonClick: PropTypes.func.isRequired,
}
QuestionModal.propTypes = QuestionModalPropTypes
QuestionModal.defaultProps = {
  previousQuestion: null,
  nextQuestion: null,
}

export default QuestionModal
