import { css } from '@emotion/core'
import { colors, spacer, misc } from '@dqp/common/styles/utilities'

export default {
  container: css`
    border-radius: ${misc.borderRadius.md};
    & > *:nth-of-type(1) {
      border-top-left-radius: ${misc.borderRadius.md};
      border-top-right-radius: ${misc.borderRadius.md};
    }
    & > *:last-child {
      border-bottom-left-radius: ${misc.borderRadius.md};
      border-bottom-right-radius: ${misc.borderRadius.md};
    }
  `,
  header: css`
    padding: 15px 25px;
    background-color: ${colors.greyLightest};
    border-bottom: 1px solid ${colors.greyLight};
  `,
  stack: css`
    ${spacer.padLR30};
    ${spacer.padBT30};
  `,
  footer: css`
    border-top: 1px solid ${colors.greyLight};
    padding: 8px 15px;
  `,
  hint: css`
    background-color: ${colors.greyLight};
    border-radius: 4px;
    padding: 10px;
  `,
  arrow: css`
    width: 10px;
  `,
  video: css`
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${misc.boxShadow.inputFieldWithBorder};

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
}
