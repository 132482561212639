import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  misc,
  mixins,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import check from '#images/check.svg'
import cross from '#images/cross.svg'
import secondsToDHMS from '#utils/secondsToDHMS'

export const styles = {
  container: css`
    box-shadow: ${misc.boxShadow.default};
    border-radius: ${misc.borderRadius.md};
    background-color: ${colors.white};
    cursor: pointer;
    &:hover {
      background-color: ${colors.greyLightest};
    }
    ${spacer.mrBT10};
    ${mixins.transition()};
    white-space: nowrap;
  `,
  innerContainer: css`
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
    }
  `,
  img: css`
    width: 30px;
    ${spacer.mrR10};
  `,
  column: css`
    padding: 20px;
    border-radius: 0px;
    :first-of-type {
      border-radius: ${misc.borderRadius.md} 0 0
        ${misc.borderRadius.md};
    }
    :last-of-type {
      border-radius: 0 ${misc.borderRadius.md} ${misc.borderRadius.md}
        0;
    }
  `,

  unattemptedCircle: css`
    border: 2px solid ${colors.black};
    display: inline-block;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    ${spacer.mrR10};
  `,
}
export const getImg = attempt => {
  if (!attempt || attempt.was_correct == null)
    return <span css={styles.unattemptedCircle} />
  if (attempt.was_correct)
    return <img src={check} alt='check-svg' css={styles.img} />
  return <img src={cross} alt='cross-svg' css={styles.img} />
}
function QuestionRow({ question, attempt, onClick }) {
  return (
    <>
      <div
        css={styles.container}
        onClick={onClick}
        className='questionRow'
        role='button'
        tabIndex='-1'
        onKeyDown={() => {}}
      >
        <div css={styles.innerContainer}>
          <Text
            size='large'
            color={colors.black}
            css={css`
              ${styles.column}
              min-width: 33%;
            `}
            className='d-flex align-items-center'
          >
            {getImg(attempt)}
            <span>Question {question.number}</span>
          </Text>

          <Text
            css={css`
              ${styles.column}
              min-width: 20%;
            `}
            size='medium'
            color={colors.grey}
          >
            {secondsToDHMS((attempt && attempt.time_elapsed) || 0)}
          </Text>

          <Text
            size='medium'
            color={colors.grey}
            css={css`
              ${styles.column};
            `}
          >
            {question.category.name}
          </Text>
        </div>
      </div>
    </>
  )
}
export const QuestionRowProps = {
  attempt: PropTypes.any,
  question: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}
QuestionRow.propTypes = QuestionRowProps
QuestionRow.defaultProps = {
  attempt: null,
  onClick: () => {},
}
export default React.memo(QuestionRow)
