import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

export const BEGIN_QUESTION_MUTATION = gql`
  mutation beginQuestion($subscriptionId: ID!, $questionId: ID!) {
    beginQuestion(
      input: {
        subscription_id: $subscriptionId
        question_id: $questionId
      }
    ) {
      question {
        id
        question
        note
        type
        category {
          id
          name
        }
        options {
          id
          option
        }
      }
      attempt {
        was_correct
        time_elapsed
      }
    }
  }
`
export const useBeginQuestion = () => {
  const [beginQuestion, { data, loading, error }] = useMutation(
    BEGIN_QUESTION_MUTATION,
  )

  return { beginQuestion, data, loading, error }
}
