import React, { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { useInterval, useUnmount, useUpdateEffect } from 'react-use'
import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online'
import QuestionModal from './QuestionModal'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import { BEGIN_QUESTION_MUTATION } from '#api/beginQuestion'
import { useUpdateQuestionTimer } from '#api/updateQuestionTimer'
import { useSubmitQuestion } from '#api/submitQuestion'
import { GET_SUBSCRIPTION_DATA_QUERY } from '#api/getSubscriptionData'
import {
  useQuestionContext,
  questionActionTypes,
} from '#context/QuestionContext'
import { GET_SUBSCRIPTION_OVERVIEW_QUERY } from '#api/getSubscriptionOverview'

const QuestionModalContainer = ({
  question,
  closeModal,
  onNextButtonClick,
  onPreviousButtonClick,
  previousQuestion,
  nextQuestion,
  setScrollTop,
}) => {
  const currentTimeElapsed = get(question, 'attempt.time_elapsed', 0)
  const questionId = question.question.id

  const [isSubmitted, setIsSubmitted] = useState(false)

  const { state, dispatch } = useQuestionContext()
  const { currentQuestion, profileId, subscriptionId } = state

  const [isFocused, setIsFocused] = useState(true)
  const { isOnline } = useNavigatorOnline()
  const shouldPauseTimer = !isOnline || !isFocused
  const prevQuestionId = useRef(questionId)
  const prevTimer = useRef()

  const [
    beginQuestion,
    { data, loading, error, called },
  ] = useMutation(BEGIN_QUESTION_MUTATION)

  const {
    updateQuestionTimer,
    loading: updateQuestionTimerLoading,
  } = useUpdateQuestionTimer()

  const {
    submitQuestion,
    loading: submitQuestionLoading,
  } = useSubmitQuestion({
    onCompleted: ({ submitQuestion: response }) => {
      dispatch({
        type: questionActionTypes.SET_CURRENT_QUESTION,
        payload: {
          ...response,
          question: {
            ...response.question,
            number: currentQuestion.question.number,
          },
        },
      })
    },
    refetchQueries: [
      {
        query: GET_SUBSCRIPTION_OVERVIEW_QUERY,
        variables: {
          profileId,
        },
      },
      {
        query: GET_SUBSCRIPTION_DATA_QUERY,
        variables: {
          id: subscriptionId,
        },
      },
    ],
  })

  const handleSubmit = async optionsIds => {
    setIsSubmitted(true)
    setScrollTop(
      document.querySelector('.ReactModal__Overlay').scrollTop,
    )
    // await updateQuestionTimer cuz if submitted after submitQuestion mutation, then we get error
    await updateQuestionTimer({
      variables: {
        questionId,
        subscriptionId,
        timeElapsed: prevTimer.current,
      },
    })
    await submitQuestion({
      variables: {
        questionId,
        subscriptionId,
        optionsIds,
      },
    })
  }

  useInterval(
    () => {
      prevTimer.current += 1
      // console.info(prevTimer.current)
    },
    shouldPauseTimer ? null : 1000,
  )

  useUpdateEffect(() => {
    updateQuestionTimer({
      variables: {
        questionId: prevQuestionId.current,
        subscriptionId,
        timeElapsed: prevTimer.current,
      },
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_DATA_QUERY,
          variables: {
            id: subscriptionId,
          },
        },
      ],
    })
    prevQuestionId.current = questionId
  }, [questionId, subscriptionId, updateQuestionTimer])

  useUnmount(() => {
    if (!isOnline) return
    if (isSubmitted) return
    updateQuestionTimer({
      variables: {
        questionId,
        subscriptionId,
        timeElapsed: prevTimer.current,
      },
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_DATA_QUERY,
          variables: {
            id: subscriptionId,
          },
        },
      ],
    })
  })

  useEffect(() => {
    const setFocusedTrue = () => setIsFocused(true)
    const setFocusedFalse = () => setIsFocused(false)

    window.addEventListener('focus', setFocusedTrue)
    window.addEventListener('blur', setFocusedFalse)
    return () => {
      window.removeEventListener('focus', setFocusedFalse)
      window.removeEventListener('blur', setFocusedFalse)
    }
  }, [setIsFocused])

  // when component mounts start question automatically.
  useEffect(() => {
    prevTimer.current = currentTimeElapsed
    beginQuestion({
      variables: {
        subscriptionId,
        questionId,
      },
    })
  }, [beginQuestion, currentTimeElapsed, questionId, subscriptionId])

  if (loading) return <Loader isRelative height={350} />
  if (error) return <Error error={JSON.stringify(error)} />
  if (called) {
    return (
      <>
        <QuestionModal
          question={{
            ...question.question,
            ...data.beginQuestion.question,
          }}
          closeModal={closeModal}
          onSubmit={handleSubmit}
          isSubmitting={
            submitQuestionLoading || updateQuestionTimerLoading
          }
          previousQuestion={previousQuestion}
          nextQuestion={nextQuestion}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </>
    )
  }

  return null
}

QuestionModalContainer.propTypes = {
  question: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  nextQuestion: PropTypes.any,
  previousQuestion: PropTypes.any,
  onNextButtonClick: PropTypes.func.isRequired,
  onPreviousButtonClick: PropTypes.func.isRequired,
  setScrollTop: PropTypes.func.isRequired,
}
QuestionModalContainer.defaultProps = {
  previousQuestion: null,
  nextQuestion: null,
}
export default QuestionModalContainer
