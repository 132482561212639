import React, { useContext, createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const QuestionStateContext = createContext()
const QuestionDispatchContext = createContext()

export const questionModalTypes = {
  CORRECT_ANSWER_MODAL: 'CORRECT_ANSWER_MODAL',
  WRONG_ANSWER_MODAL: 'WRONG_ANSWER_MODAL',
  CONTAINER_MODAL: 'CONTAINER_MODAL',
}

export const questionActionTypes = {
  SET_CURRENT_QUESTION: 'SET_QUESTIONS',
  SET_MODAL: 'SET_MODAL',
  RESET: 'RESET',
}

const initialState = {
  currentQuestion: null,
  modalType: '',
  profileId: '',
  subscriptionId: '',
}

const questionReducer = (state, action) => {
  switch (action.type) {
    case questionActionTypes.SET_CURRENT_QUESTION:
      return { ...state, currentQuestion: action.payload }
    case questionActionTypes.SET_MODAL:
      return { ...state, modalType: action.payload }
    case questionActionTypes.RESET:
      return { ...state, currentQuestion: null, modalType: '' }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
export const QuestionProvider = ({
  children,
  profileId,
  subscriptionId,
}) => {
  const [state, dispatch] = useReducer(questionReducer, {
    ...initialState,
    profileId,
    subscriptionId,
  })

  return (
    <QuestionStateContext.Provider value={state}>
      <QuestionDispatchContext.Provider value={dispatch}>
        {children}
      </QuestionDispatchContext.Provider>
    </QuestionStateContext.Provider>
  )
}

const useQuestionState = () => {
  return useContext(QuestionStateContext)
}

const useQuestionDispatch = () => {
  return useContext(QuestionDispatchContext)
}

export const useQuestionContext = () => {
  return {
    state: useQuestionState(),
    dispatch: useQuestionDispatch(),
  }
}

QuestionProvider.propTypes = {
  children: PropTypes.any.isRequired,
  profileId: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
}
