import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'

export const GET_ANSWERED_QUESTION_QUERY = gql`
  query getAnsweredQuestion($subscriptionId: ID!, $questionId: ID!) {
    getAnsweredQuestion(
      subscription_id: $subscriptionId
      question_id: $questionId
    ) {
      optionsListWithChosenOptions @client
      question {
        id
        question
        note
        type
        category {
          id
          name
        }
        options {
          id
          option
          is_correct
        }
        solution_video
      }
      attempt {
        was_correct
        started_at
        answered_options {
          id
          is_correct
          option
        }
        time_elapsed
        finished_at
      }
    }
  }
`
export const useGetAnsweredQuestion = () => {
  const [
    getAnsweredQuestion,
    { data, loading, error },
  ] = useLazyQuery(GET_ANSWERED_QUESTION_QUERY)

  return { getAnsweredQuestion, data, loading, error }
}
